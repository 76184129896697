import React from 'react';
import { graphql } from "gatsby"
import { compose } from 'recompose';
import HubspotForm from 'react-hubspot-form'
import { RichText } from 'prismic-reactjs';

import htmlSerializer from '../../../utils/htmlSerializer';

import DashboardWrapper from '../../../components/Dashboard/DashboardWrapper';
import DocumentCard from '../../../components/Common/DocumentCard';
import DocumentCardsContainer from '../../../components/Common/DocumentCardsContainer';
import PortalVideoUpdateNew from '../../../components/Common/PortalVideoUpdateNew';
import Layout from '../../../components/layout';
import { withAuthorization } from '../../../components/Session';
import HeadData from '../../../data/HeadData';
import BackButton from '../../../components/Common/BackButton';

const SRAProductPageBase = ({ data }) => {
  const doc = data.prismicSraProductPage

  return (
    <DashboardWrapper pageName={doc.data.title}>
      <div className="relative pt-16 pb-32 md:pt-20 md:px-8 px-0">
        <div className="container mx-auto px-4">
          <div className="w-full max-w-screen-lg mt-6">
            <h2 className="text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold mb-6">
              {doc.data.top_section_title}
            </h2>
            <RichText render={doc.data.top_section_description.richText} htmlSerializer={htmlSerializer} />
          </div>

          <DocumentCardsContainer containerTitle="Downloadable Documents">
            {doc.data.pdf_files_list.map(item => {
              return (
                item.coming_soon
                ?
                <DocumentCard
                  cardTitle={item.pdf_file_title}
                  internalLink
                  buttonComingSoon
                />
                :
                <DocumentCard
                  cardTitle={item.pdf_file_title}
                  buttonLink={item.pdf_download_link.url}
                />
              )
            })}
          </DocumentCardsContainer>

          {doc.data.form_section_shown
            ?
            <>
              <div className="w-full max-w-screen-lg mt-16 mb-6">
                <h2 className="text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold">
                  {doc.data.form_section_title}
                </h2>
                <p className="text-base md:text-xl text-gray-800 mt-6">{doc.data.form_section_description}</p>
              </div>        

              <div className="max-w-screen-md bg-white mt-16 shadow-md rounded-md p-6">
                <HubspotForm
                  portalId='23182726'
                  formId={doc.data.form_id ? doc.data.form_id : 'dfa38579-9c57-4e76-8e3d-5fd33b0f232e'}
                  loading={<div className='italic'>Loading form...</div>}
                />
              </div>
            </>
            :
            null      
          }

          {doc.data.video_section_shown
            ?
            <div className="w-full max-w-screen-lg grid grid-cols-1 gap-6 xl:gap-16 mt-8 xl:mt-16">
              <PortalVideoUpdateNew 
                videoID={doc.data.wistia_video_id} 
                videoTitle={doc.data.video_title} 
                withoutInfo
              />
            </div>
            :
            null
          }

          <BackButton buttonLink="/sales-support-documents/collateral" />
        </div>
      </div>
    </DashboardWrapper>
  )
};

const condition = (authUser) => !!authUser;

const SRAProductPage = compose(withAuthorization(condition))(
  SRAProductPageBase,
);

const ProductPage = ({ data }) => {
  const doc = data.prismicSraProductPage

  return (
    <Layout>
      <HeadData title={doc.data.title} />
      <SRAProductPage data={data} /> {/* Pass data here */}
    </Layout>
  )
};

export default ProductPage;

export const SRAProductPageNewQuery = graphql`
  query($id: String!) {
    prismicSraProductPage(id: { eq: $id }) {
      id
      uid
      data {
        title
        top_section_title
        top_section_description {
          richText
        }
        form_section_shown
        form_id
        form_section_title
        form_section_description
        video_section_shown
        wistia_video_id
        video_title
        pdf_files_list {
          pdf_file_title
          pdf_download_link {
            url
          }
          coming_soon
        }
      }
    }
  }
`